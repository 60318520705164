@use '../../node_modules/@angular/material/index' as mat;
@import '../variables';
@import "../theme";

@mixin common-calendar-styles {
  width: 220px;
  box-sizing: border-box;
  padding: 0;

  @include devices(desktop-xl) {
    width: 300px;
    &.mat-datepicker-content .mat-datepicker-content-container-with-custom-header .mat-calendar {
      width: 300px;
    }

    &.mat-datepicker-content .mat-calendar {
      width: 300px;
    }
    .mat-calendar-content {
      width: 300px;
      box-sizing: border-box;
    }
  }

  @include devices(desktop-xl) {
    .mat-calendar-body-cell {
      @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    }
    .mat-calendar-table-header, .mat-calendar-table-header th {
      @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    }

    @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    width: 300px;
    &.mat-datepicker-content-container-with-custom-header .mat-calendar {
      width: 300px;
    }
  }

  .mat-calendar {
    width: 100%;
    @include devices(desktop-xl) {
      width: 300px;
    }
  }
}

.calendar-wrapper {
  .mat-calendar {
    .mat-calendar-body-label {
      opacity: 0;
    }
    .mat-calendar-body-label[colspan="7"] {
      display: none;
    }
    .mat-calendar-table-header-divider {
      display: none;
    }
    .mat-calendar-table-header {
      .mat-calendar-abbr {
        color: $dark-grey;
      }
    }
  }
}

.mat-calendar {
  .mat-calendar-body-label {
    opacity: 0;
  }
  .mat-calendar-body-label[colspan="7"] {
    display: none;
  }
  .mat-calendar-table-header-divider {
    display: none;
  }
  .mat-calendar-table-header {
    .mat-calendar-abbr {
      color: $dark-grey;
    }
  }
}

.mat-datepicker-content .mat-datepicker-content-container-with-custom-header .mat-calendar {


  width: auto;
}

.mat-datepicker-content-container-with-actions {
  @include common-calendar-styles;

  .mat-datepicker-actions {
    .datepicker-actions-wrapper {
      button {
        margin: 0;
      }
    }
  }
  background-color: white;
  padding: 0;
  border: 1px solid $dark-grey;
  filter: drop-shadow(4px 4px 0 rgba(0, 0, 0, 0.1));
  border-radius: 4px;

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: $dark-grey;
    right: 10px;
    top: -16px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: white;
    right: 10px;
    top: -15px;
    transform: translate(0, 0.5px)
  }

  .mat-datepicker-actions {
    padding: 0;
    border-top: 1px solid black;
    width: calc(100% + 1px);
  }
}

.calendar-wrapper {
  @include common-calendar-styles;
}
