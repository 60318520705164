@import "variables";

@mixin devices ($breakpoint) {
  @if $breakpoint == laptop {
    @media screen
    and (max-width: 1800px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media screen
    and (min-width: 1801px) and (max-width: 2799px) {
      @content;
    }
  }

  @if $breakpoint == desktop-xl {
    @media screen
    and (min-width: 2800px) {
      @content;
    }
  }
  @if $breakpoint == laptop-small {
    @media screen
    and (max-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media screen
    and (max-width: 850px) {
      @content;
    }
  }
}

@mixin main-padding {
  padding: $main-padding-size;
  @include devices(laptop) {
    padding: $main-padding-size-y-laptop $main-padding-size-x-laptop;
  }
  @include devices(tablet) {
    padding: $main-padding-size-y-tablet $main-padding-size-x-tablet;
  }
}
