@import '../mixins';
@import '../variables';

.side-dialog-panel {
  display: block;
  background-color: white;
  box-sizing: border-box;
  margin: 20px;

  height: calc(100vh - 40px);
  width: 50%;
  min-width: 600px;

  box-shadow: inset 4px 0 0 rgba(33, 37, 41, 0.1);
  border-radius: 4px;

  @include devices(laptop) {
    width: 60%;
  }

  .side-dialog-header-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 15px;

    p {
      margin: 0;
    }

    h1 {
      margin: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .preview-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    hr {
      width: 100%;
    }
  }

  .preview-header {
    padding-bottom: 20px;
  }

  .preview-body--scrollable {
    padding-top: 20px;
    overflow-y: auto;

    .preview-row--separated {
      padding-top: 20px;
      hr {
        margin: 20px 0 0 0;
      }
    }

    .preview-row--separated:first-child {
      padding-top: 0;
    }
  }
}

.center-dialog-panel {
  background-color: white;
  border: 1px solid $dark-grey;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: calc(100vh - 40px);
}

.overlay-backdrop-transparent {
  background-color: transparent;
}

.overlay-backdrop {
  background-color: rgba($dark-grey, 0.5);
}

.cdk-overlay-backdrop {
  transition: none;
}

.confirm-dialog {
  strong {
    font-weight: 600;
  }
}

.side-dialog-action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 20px;
}

.preview-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.side-dialog--padding-horizontal {
  padding: 0 40px;
}


.dialog-container {
  .dialog {
      .full-height-page > .main-header-row {
        margin-right: 120px;
        .main-header {
          flex-wrap: wrap;
        }
      }
  }
}
