@import '../variables';
@import '../mixins';


html, body {
  height: 100%;
}

h1, p {
  color: $dark-grey;
}

.mat-typography p {
  margin-bottom: 10px;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.mat-typography h1 {
  margin: 0;
}

hr.separator {
  width: 100%;
  flex-shrink: 0;

  &.separator--no-margin {
    margin: 0;
  }
}

.flex-space-between{
  display: flex;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.row-gap-10 {
  row-gap: 10px;
}

.row-gap-5 {
  row-gap: 5px;
}

.flex-wrap-small {
  @include devices(laptop-small) {
    flex-wrap: wrap;
  }
}

.flex-wrap-tablet {
  @include devices(tablet) {
    flex-wrap: wrap;
  }
}

.d-none-small {
  @include devices(laptop-small) {
    display: none!important;
  }
}

.d-small-only {
  display: none!important;
  @include devices(laptop-small) {
    display: initial!important;
  }
}

.flex-column {
  flex-direction: column;
}

.mat-typography .caption-bold {
  font-weight: 500;
}

hr {
  background-color: $light-grey-fourth;
  height: 1px;
  border: none;
}

.color-primary {
  color: $primary-color;
}

.color-error {
  color: $status-error;
}

.color-warning-secondary {
  color: $status-warning-secondary;
}

.color-success {
  color: $status-success;
}

.background-success {
  background-color: $status-success;
}

.color-dark-grey-third {
  color: $dark-grey-third;
}

.color-dark-grey-fourth {
  color: $dark-grey-fourth;
}

.color-light-grey-primary {
  color: $light-grey-primary;
}

.color-dark-grey {
  color: $dark-grey;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.center-gap-10 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.align-center-gap-10 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.align-center-gap-20 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.align-center-gap-40 {
  display: flex;
  align-items: center;
  gap: 40px;
}

.align-center-gap-5 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.align-center-gap-0 {
  display: flex;
  align-items: center;
}

.align-center{
  display: flex;
  align-items: center;
}

.flex-col-10 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.neighbour-pt-20 + .neighbour-pt-20 {
  padding-top: 20px;
}

.center-gap-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.center-gap-20 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-italic {
  font-style: italic;
}

.padding-t-20 {
  padding-top: 20px;
}

.p-vert-10 {
  padding: 10px 0;
}

.p-20 {
  padding: 20px;
}

.mat-typography .margin-0, hr.margin-0 {
  margin: 0;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.no-margins {
  h1, h2, h3, p {
    margin: 0;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.j-space-between {
  justify-content: space-between;
}

.sizing-b-box {
  box-sizing: border-box;
}

.color-error-pointer {
  color: $status-error;
  cursor: pointer;
}

.color-warning-secondary-pointer {
  color: $status-warning-secondary;
  cursor: pointer;
}

.p-5 {
  padding: 5px;
}

.whitespace-pre {
  white-space: pre;
}
