@import "../mixins";
@mixin platform-icon-col($size, $laptop-size, $desktop-xl-size) {
  font-size: $size;
  height: $size!important;
  @include devices(laptop) {
    font-size: $laptop-size;
    height: $laptop-size!important;
  }

  @include devices(desktop-xl) {
    font-size: $desktop-xl-size;
    height: $desktop-xl-size!important;
  }
}

.ios-icon-col {
  @include platform-icon-col(20px, 18px, 28px);
  position: relative;
  top: -1px;
}

.ios-icon-col--with-name {
  @include platform-icon-col(20px, 18px, 28px);
  position: relative;
  top: -1px;
  @include devices(laptop) {
    top: -2px;
  }
}

.web-icon-col {
  @include platform-icon-col(16px, 14px, 22px);
}


.web-icon-col--with-name {
  position: relative;
  @include platform-icon-col(16px, 14px, 22px);
  top: -1px;
  @include devices(laptop) {
    top: -2px;
  }
}

.samsung-icon-col {
  @include platform-icon-col(17px, 15px, 22px);
}

.samsung-icon-col--with-name {
}

.samsung-icon-xs {
  @include platform-icon-col(14px, 12px, 20px);
}

.android-icon-col {
  @include platform-icon-col(20px, 18px, 28px);
}


.android-icon-col--with-name {
  @include platform-icon-col(20px, 18px, 28px);
  @include devices(laptop) {
    position: relative;
    top: -1px;
  }
}


.ios-icon-xs {
  position: relative;
  top: -1px;
  left: -1px;
  @include platform-icon-col(16px, 14px, 22px);
}

.android-icon-xs {
  position: relative;
  top: -1px;
  @include platform-icon-col(16px, 14px, 22px);
}

.web-icon-xs {
  position: relative;
  @include platform-icon-col(14px, 12px, 22px);
}
