@import '../mixins';

.main-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  h1 {
    margin: 0;
    white-space: nowrap;
    @include devices(laptop-small) {
      white-space: initial;
    }
  }
}

.main-header-row {
  overflow-x: auto;
  column-gap: 100px;
  row-gap: 20px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 80px;
  box-sizing: content-box;
  min-height: 46px;
  height: initial;

  &.main-header-row--small-gap {
    column-gap: 40px;
    @media screen and (max-width: 1400px) {
      flex-wrap: wrap;
    }
  }

  @include devices(laptop) {
    padding-bottom: 20px;
  }

  @include devices(laptop-small) {
    flex-wrap: wrap;
  }
  flex-shrink: 0;

  &.main-header-row--embedded {
    padding-top: 40px;
    padding-bottom: 40px;

    @include devices(laptop) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .products-select + button {
    white-space: initial;
    text-align: left;
  }

  .products-select-wrapper > span {
      white-space: initial;
  }
}

.full-height-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.list-table {
  min-width: 0;
  overflow: auto;
  flex-grow: 1;
}

.list-header-form {
  display: flex;
  align-items: center;
  gap: 20px;
}

.column--no-tags-tablet {
  .tag {
    @include devices(laptop-small) {
      display: none!important;
    }
  }
}

.column-width-narrow--no-tags-tablet {
  .tag {
    @include devices(laptop-small) {
      display: none!important;
    }
  }

  .cell-with-ellipsis {
    max-width: 250px!important;

    @include devices(laptop) {
      max-width: 220px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @include devices(laptop-small) {
      max-width: 150px!important;
    }

    @media screen and (max-width: 1400px) {
      max-width: 150px!important;
    }

    @include devices(tablet) {
      max-width: 100px!important;
    }
  }
}

.column-width-narrow--hideable-tablet {
  @include devices(tablet) {
    display: none;
  }

  .cell-with-ellipsis {
    max-width: 250px!important;

    @include devices(laptop) {
      max-width: 220px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @include devices(laptop-small) {
      max-width: 150px!important;
    }

    @media screen and (max-width: 1400px) {
      max-width: 150px!important;
    }

    @include devices(tablet) {
      max-width: 100px!important;
    }
  }
}


.column--hideable-tablet {
  @include devices(tablet) {
    display: none;
  }
}

.column-width-narrow {
  .cell-with-ellipsis {
    max-width: 250px!important;

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @include devices(laptop-small) {
      max-width: 150px!important;
    }

    @media screen and (max-width: 1400px) {
      max-width: 150px!important;
    }

    @include devices(tablet) {
      max-width: 100px!important;
    }
  }
}

.column--hideable-laptop-small {
  @include devices(laptop-small) {
    display: none;
  }
  @include devices(tablet) {
    display: none;
  }
}

.column-waterfalls-list {
  .cell-with-ellipsis {
    max-width: 220px!important;

    @include devices(laptop) {
      max-width: 200px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @media screen and (max-width: 1450px) {
      max-width: 110px!important;
    }
  }

  @include devices(laptop-small) {
    display: none;
  }
  @include devices(tablet) {
    display: none;
  }
}

.column-waterfalls-region {
  .cell-with-ellipsis {
    max-width: 220px!important;

    @include devices(laptop) {
      max-width: 160px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @media screen and (max-width: 1450px) {
      max-width: 110px!important;
    }
  }
}

.column-asset-name {
  cursor: pointer;
  .cell-with-ellipsis {
    max-width: 300px!important;

    @include devices(laptop) {
      max-width: 200px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @media screen and (max-width: 1450px) {
      max-width: 150px!important;
    }
  }
}

.asset-ratio-column {
  @media screen and (max-width: 1450px) {
    display: none;
  }
}

.column-whitelisted-platforms {
  .cell-with-ellipsis {
    max-width: 220px!important;

    @include devices(laptop) {
      max-width: 200px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @media screen and (max-width: 1450px) {
      max-width: 110px!important;
    }
  }
}


.column-waterfalls-list-name {
  .cell-with-ellipsis {
    max-width: 220px!important;

    @include devices(laptop) {
      max-width: 200px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @media screen and (max-width: 1400px) {
      max-width: 110px!important;
    }
  }
}


.column-waterfalls-tab {
  .cell-with-ellipsis {
    max-width: 220px!important;

    @include devices(laptop) {
      max-width: 200px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @media screen and (max-width: 1580px) {
      max-width: 110px!important;
    }
  }

  @media screen and (max-width: 1300px) {
    display: none;
  }

  @include devices(laptop-small) {
    display: none;
  }
  @include devices(tablet) {
    display: none;
  }
}


.column-waterfalls-tab-name {
  .cell-with-ellipsis {
    max-width: 220px!important;

    @include devices(laptop) {
      max-width: 200px!important;
    }

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }

    @media screen and (max-width: 1400px) {
      max-width: 110px!important;
    }
  }
}

.column-switch-name {
  .cell-with-ellipsis {
    max-width: 250px!important;

    @include devices(desktop-xl) {
      max-width: 500px!important;
    }
  }
}

.column-switch-platform {
  .cell-with-ellipsis {
    max-width: 240px!important;
  }

  @include devices(laptop) {
    .cell-with-ellipsis {
      max-width: 140px!important;
    }
  }
}

.column-switch-ad-unit {
    max-width: 100px!important;
    min-width: 100px!important;
}

.header-switch {
  .mat-body-1 {
  font-size: 14px;
  }

  @include devices(laptop) {
    .mat-body-1 {
      font-size: 12px;
    }
  }
}



tr.mat-mdc-row.row-background-light-grey-fifth {
  background-color: $light-grey-fifth;
  &:nth-child(2n+1) {
    background-color: $light-grey-fifth;
  }
}

.col-15 {
  max-width: 15vw;
  min-width: 40px;
}
