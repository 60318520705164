$primary-color: #204075;
$font-weight-semi-bold: 600;
$dark-grey: #212529;
$dark-grey-secondary: #343A40;
$dark-grey-third: #495057;
$dark-grey-fourth: #6C757D;
$light-grey-primary: #ADB5BD;
$light-grey-secondary: #CED4DA;
$light-grey-third: #DEE2E6;
$light-grey-fourth: #E9ECEF;
$light-grey-fifth: #F8F9FA;
$neutral-primary: white;
$status-success: #28A745;
$status-error: #D72E3D;
$status-warning: #FFB90C;
$status-warning-secondary: #ED7117;


$main-padding-size: 40px;
$main-padding-size-x-laptop: 40px;
$main-padding-size-y-laptop: 20px;
$main-padding-size-x-tablet: 20px;
$main-padding-size-y-tablet: 20px;


$button-desktop-xl-narrow: 42px;
$button-desktop-xl: 56px;
