@import '../variables';
@import "../mixins";

.dropzone-wrapper {
  background-color: $neutral-primary;
  border: 1px solid $light-grey-primary;
  display: inline-block;
  border-radius: 4px;
  padding: 20px;

  @include devices(laptop) {
    padding: 10px;
  }

  // used in file-dnd.directive
  &.file-over {
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
    .dropzone {
      border-style: solid;
    }
  }

  &.dropzone-wrapper--success {
    border-color: $status-success;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
    .dropzone {
      border-color: $status-success;
    }
  }

  &.dropzone-wrapper--error {
    border-color: $status-error;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
    .dropzone {
      border-color: $status-error;
    }
  }
}

.dropzone {
  border: 0.5px dashed $light-grey-primary;
  border-radius: 4px;
  background-color: $light-grey-fifth;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  p {
    margin: 0;
  }

  @include devices(laptop) {
    padding: 10px;
  }
}
