@use '../node_modules/@angular/material/index' as mat;
@import 'variables';
@import "mixins";

$my-custom-typography: mat.define-typography-config(
  $font-family: '"Inter", sans-serif',
  $headline-1: mat.define-typography-level(32px, 32px, 400, $letter-spacing: -0.017em), // Heading 32 px/H1 | Regular
  $headline-5: mat.define-typography-level(32px, 32px, 400, $letter-spacing: -0.017em), // Heading 32 px/H1 | Regular
  $body-1: mat.define-typography-level(16px, 19px, 600, $letter-spacing: -0.017em), // Text 16 px/Txt1 Semibold
  $body-2: mat.define-typography-level(16px, 19px, 400, $letter-spacing: -0.017em), // Text 16 px/Txt1 | Regular
  $caption: mat.define-typography-level(14px, 17px, 400, $letter-spacing: -0.017em), // Text 14px/Txt2 | Regular
  $button: mat.define-typography-level(16px, 19px, 300, $letter-spacing: -0.017em)
);

$my-custom-typography-laptop: mat.define-typography-config(
  $font-family: '"Inter", sans-serif',
  $headline-1: mat.define-typography-level(22px, 24px, 400, $letter-spacing: -0.017em), // Heading 32 px/H1 | Regular
  $headline-5: mat.define-typography-level(22px, 24px, 400, $letter-spacing: -0.017em), // Heading 32 px/H1 | Regular
  $body-1: mat.define-typography-level(14px, 16px, 600, $letter-spacing: -0.017em),// Text 16 px/Txt1 Semibold
  $body-2: mat.define-typography-level(14px, 16px, 400, $letter-spacing: -0.017em), // Text 16 px/Txt1 | Regular
  $caption: mat.define-typography-level(13px, 16px, 400, $letter-spacing: -0.017em), // Text 14px/Txt2 | Regular
  $button: mat.define-typography-level(13px, 15px, 300, $letter-spacing: -0.017em)
);


$my-custom-typography-desktop-xl: mat.define-typography-config(
  $font-family: '"Inter", sans-serif',
  $headline-1: mat.define-typography-level(38px, 42px, 400, $letter-spacing: -0.017em), // Heading 32 px/H1 | Regular
  $headline-5: mat.define-typography-level(38px, 42px, 400, $letter-spacing: -0.017em), // Heading 32 px/H1 | Regular
  $body-1: mat.define-typography-level(20px, 24px, 600, $letter-spacing: -0.017em), // Text 16 px/Txt1 Semibold
  $body-2: mat.define-typography-level(20px, 24px, 400, $letter-spacing: -0.017em), // Text 16 px/Txt1 | Regular
  $caption: mat.define-typography-level(18px, 22px, 400, $letter-spacing: -0.017em), // Text 14px/Txt2 | Regular
  $button: mat.define-typography-level(20px, 24px, 300, $letter-spacing: -0.017em)
);

$light-primary-text: white;

$boombit-palette: (
  50: $primary-color,
  100: $primary-color,
  200: $primary-color,
  300: $primary-color,
  400: $primary-color,
  500: $primary-color,
  600: $primary-color,
  700: $primary-color,
  800: $primary-color,
  900: $primary-color,
  A100: $primary-color,
  A200: $primary-color,
  A400: $primary-color,
  A700: $primary-color,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$app-primary: mat.define-palette($boombit-palette, 400);

$app-accent: mat.define-palette($boombit-palette, 700);

$app-warn: mat.define-palette($boombit-palette, 400);


$crosspromo-panel-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $my-custom-typography
));

$crosspromo-panel-theme-laptop: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $my-custom-typography-laptop
));

$crosspromo-panel-theme-desktop-xl: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $my-custom-typography-desktop-xl
));


.mat-button-size {
  @include devices(laptop) {
    font-size: 13px;
    line-height: 15px;
  }
}

.mat-button-size--narrow {
  @include mat.typography-level($my-custom-typography, 'caption');

  @include devices(laptop) {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
  }

  @include devices(desktop-xl) {
    @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    font-weight: 300;
  }
}

.mat-typography h1.h1-semibold {
  font-weight: 600;
}

.mat-typography .txt-3-medium {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;

  @include devices(laptop) {
    font-size: 11px;
    line-height: 14px;
  }

  @include devices(desktop-xl) {
    font-size: 16px;
    line-height: 18px;
  }
}

.mat-typography .txt-3-regular {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;

  @include devices(laptop) {
    font-size: 11px;
    line-height: 14px;
  }

  @include devices(desktop-xl) {
    font-size: 16px;
    line-height: 18px;
  }
}

.mat-typography .text-14-txt-2-semi-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.017em;

  @include devices(laptop) {
    font-size: 13px;
    line-height: 15px;
  }

  @include devices(desktop-xl) {
    @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    font-weight: 600;
  }
}

.mat-typography .text-16-txt-1-light {
  font-weight: 300;
  letter-spacing: -0.017em;
}

.mat-typography .text-16-txt-1-medium {
  font-weight: 500;
  letter-spacing: -0.017em;
}

.mat-typography .text-16-txt-1-semi-bold {
  font-weight: 600;
}

.mat-typography h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $dark-grey;
  letter-spacing: -0.017em;

  @include devices(laptop) {
    font-size: 17px;
    line-height: 18px;
  }

  @include devices(desktop-xl) {
    font-size: 26px;
    line-height: 28px;
  }
}

.mat-typography .h2-medium {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: $dark-grey;
  letter-spacing: -0.017em;

  @include devices(laptop) {
    font-size: 17px;
    line-height: 18px;
  }

  @include devices(desktop-xl) {
    font-size: 26px;
    line-height: 28px;
  }
}

.mat-typography .text-14-txt-2-light {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: $dark-grey;

  @include devices(laptop) {
    font-size: 13px;
    line-height: 15px;
  }

  @include devices(desktop-xl) {
    @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    font-weight: 300;
  }
}

.mat-typography .text-14-txt-2-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  @include devices(laptop) {
    font-size: 13px;
    line-height: 15px;
  }

  @include devices(desktop-xl) {
    @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    font-weight: 500;
  }
}

.mat-typography .text-12-txt-3-regular {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  @include devices(laptop) {
    font-size: 11px;
    line-height: 14px;
  }

  @include devices(desktop-xl) {
    font-size: 16px;
    line-height: 18px;
  }
}

.mat-typography .text-12-txt-3-medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  @include devices(laptop) {
    font-size: 11px;
    line-height: 14px;
  }

  @include devices(desktop-xl) {
    font-size: 16px;
    line-height: 18px;
  }
}

.mat-typography .text-12-txt-3-light {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  @include devices(laptop) {
    font-size: 11px;
    line-height: 14px;
  }

  @include devices(desktop-xl) {
    font-size: 16px;
    line-height: 18px;
  }
}

@mixin button-styles {
  .mat-mdc-button-base .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .basic-button.mat-mdc-button-base {
    height: 46px;
    font-weight: 400;

    @include devices(laptop) {
      height: 34px;
      line-height: 30px;
    }

    @include devices(desktop-xl) {
      height: $button-desktop-xl;
    }

    border-radius: 4px;

    &.button-narrow {
      font-size: 14px;
      line-height: 17px;
      height: 36px;

      @include devices(laptop) {
        height: 28px;
        font-size: 13px;
        line-height: 14px;
      }

      @include devices(desktop-xl) {
        height: $button-desktop-xl-narrow;
        @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
      }
    }
    color: $dark-grey;
    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  .mat-primary {
    &.mat-mdc-outlined-button, &.mat-mdc-unelevated-button {
      min-width: max-content;
      height: 46px;

      @include devices(laptop) {
        height: 34px;
        line-height: 30px;
      }

      @include devices(desktop-xl) {
        height: $button-desktop-xl;
      }

      border-radius: 4px;

      &.button-narrow {
        font-size: 14px;
        line-height: 17px;

        @include devices(laptop) {
          font-size: 12px;
          line-height: 14px;
        }

        @include devices(desktop-xl) {
          @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
        }
      }

      &.background-success {
        background-color: $status-success;
      }
    }

    &.mat-mdc-unelevated-button.button-narrow {
      height: 36px;

      @include devices(laptop) {
        height: 28px;
      }

      @include devices(desktop-xl) {
        height: $button-desktop-xl-narrow;
      }
    }

    &.mat-mdc-unelevated-button.button-square-icon {
      height: 36px;
      width: 36px;
      padding:0;
      box-sizing: border-box;
      min-width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      line-height: 15px;


      @include devices(laptop) {
        height: 28px;
        width: 28px;
        min-width: 28px;
      }

      @include devices(desktop-xl) {
        height: $button-desktop-xl-narrow;
        width: $button-desktop-xl-narrow;
        min-width: $button-desktop-xl-narrow;
      }
    }


    &.mat-mdc-outlined-button {
      min-width: max-content;
      font-weight: 400;

      &:not(:disabled) {
        background-color: $neutral-primary;
        border: 1px solid $primary-color;
      }

      &.button-narrow {
        height: 36px;

        @include devices(laptop) {
          height: 28px;
        }

        @include devices(desktop-xl) {
          height: $button-desktop-xl-narrow;
        }
      }

      &.button-square-icon {
        height: 36px;
        width: 36px;
        padding: 0;
        box-sizing: border-box;
        min-width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        line-height: 15px;

        @include devices(laptop) {
          height: 28px;
          width: 28px;
          min-width: 28px;
        }

        @include devices(desktop-xl) {
          height: $button-desktop-xl-narrow;
          width: $button-desktop-xl-narrow;
          min-width: $button-desktop-xl-narrow;
        }
      }
    }
  }
  .mat-primary .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
    .mat-primary.mat-mdc-outlined-button:disabled {
      color: $light-grey-primary;
      border: 1px solid $light-grey-primary;
    }

    .mat-primary.mat-mdc-unelevated-button:disabled {
      background-color: $light-grey-primary;
      color: white;
    }

  .mat-mdc-outlined-button.select-button {
    @include mat.typography-level($my-custom-typography, 'caption');

    @include devices(laptop) {
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
    }

    @include devices(desktop-xl) {
      @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
      font-weight: 400;
    }



    color: $dark-grey;
    background-color: $neutral-primary;

    padding: 0 20px;
    box-sizing: border-box;
    height: 36px;

    @include devices(laptop) {
      height: 28px;
    }

    @include devices(desktop-xl) {
      height: $button-desktop-xl-narrow;
    }

    display: flex;
    justify-content: center;
    align-items: center;


    border-radius: 4px;
    &:not(:disabled) {
      border: 1px solid #212529;
    }
    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
    }
  }
}

@include button-styles;

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}
