@use '../../node_modules/@angular/material/index' as mat;
@import '../variables';
@import '../mixins';
@import '../theme';

.add-form-header {
  flex-shrink: 0;
  padding-top: 80px;
  padding-bottom: 40px;
  @include devices(laptop) {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  h1 {
    margin-bottom: 10px;
  }

  &.no-padding-top {
    padding-top: 0;
    @include devices(laptop) {
      padding-bottom: 20px;
    }
  }

  &.add-form-header--narrow {
    padding-top: 40px;
    padding-bottom: 40px;

    @include devices(laptop) {
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
}

.form-control {
  position: relative;
  max-width: 336px;
  min-width: 120px;
  width: 300px;
  flex: 1 1 120px;
  @include mat.typography-level($my-custom-typography, 'caption');

  @include devices(laptop) {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    max-width: 310px;
  }

  @include devices(tablet) {
    max-width: 250px;
  }

  @include devices(desktop-xl) {
    @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
  }

  &.select {
    min-width: max-content;
  }

  &.select--clipped {
    flex: 1 1 300px;
    width: 300px;
    max-width: 350px;
    min-width: 300px;
  }

  .label{
    padding-bottom: 5px;
  }

  button.invalid {
    border: 1px solid $status-error;
  }

  input {
    @include mat.typography-level($my-custom-typography, 'caption');

    @include devices(laptop) {
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
    }

    @include devices(desktop-xl) {
      @include mat.typography-level($my-custom-typography-desktop-xl, 'caption');
    }

    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    height: 36px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $light-grey-primary;
    caret-color: $dark-grey;
    color: $dark-grey;

    @include devices(laptop) {
      height: 28px;
    }

    @include devices(desktop-xl) {
      height: $button-desktop-xl-narrow;
    }

    &:focus {
      outline: none;
      border: 1px solid $dark-grey-fourth;
    }

    &.ng-invalid.ng-touched {
      border: 1px solid $status-error;
    }
  }

  .error-message {
    position: absolute;
    bottom: -17px;
    left: 0;
    font-size: 12px;
    white-space: nowrap;
    color: $status-error;

    @include devices(desktop-xl) {
      bottom: -20px;
      font-size: 16px;
      line-height: 18px;
    }
  }

  :-moz-placeholder {
    color: $light-grey-primary;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: $light-grey-primary;
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: $light-grey-primary;
  }

  ::-webkit-input-placeholder {
    color: $light-grey-primary;
  }

  :-ms-input-placeholder {
    color: $light-grey-primary;
  }

  ::placeholder {
    color: $light-grey-primary;
  }
}


.mat-typography {
  .form-controls-row {
    p, h1, h2 {
      margin-bottom: 0;
    }
  }
}

.form-controls-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 20px;
  padding: 0 20px;
  p, h1, h2 {
    margin-bottom: 0;
  }

  &.form-controls-row--align-center {
    align-items: center;
  }

  &.row-gap-10 {
    row-gap: 10px;
  }
}

.form-controls-row+.form-controls-row {
  padding-top: 20px;

  @include devices(desktop-xl) {
    padding-top: 30px;
  }
}

.form-controls-group {
  padding: 30px 0;
  background: $light-grey-fifth;
  border-radius: 4px;
  margin-bottom: 10px;

  hr {
    background-color: $light-grey-third;
    height: 1px;
    border: none;
    margin: 20px 0;
  }

  &.form-controls-group--p-20 {
    padding: 20px 0;

    @include devices(laptop) {
      padding: 15px 0;
    }
  }

  @include devices(laptop) {
    padding: 15px 0;
  }
}

.add-form-action-buttons {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap:5px;
  padding-top: 20px;
  @include devices(laptop) {
    padding-top: 10px;
  }
}

.form-page-content {
  min-height: 200px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: visible;
}

.form-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  hr {
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 20px;
  }
}

.nav-separator {
  position: absolute;
  left:0;
  width: 100%;
  margin-top: 40px;
  @include devices(laptop) {
    margin-top: 20px;
  }
}

.select-form-button {
  &.mat-mdc-outlined-button.select-button:not(:disabled) {
    border: 1px solid $light-grey-primary;
  }
  .select-placeholder {
    color: $light-grey-primary;
  }
  &.focus {
    filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.1));
    border: 1px solid $dark-grey;
    .select-placeholder, .dropdown-icon{
      color: $dark-grey;
    }
  }

  &.mat-mdc-outlined-button .mdc-button__label {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inner-form-page {
  padding-top: 40px;
  @include devices(laptop) {
    padding-top: 20px;
  }

  &.inner-form-page--padding-b {
    padding-bottom: 20px;
  }
}

.form-shadow-bottom {
  &:after {
    display: block;
    width: 100%;
    background-color: transparent;
    position: sticky;
    bottom: 0;
    height: 10px;
    content: "";
    -moz-box-shadow: inset 0 -10px 10px -10px $light-grey-primary;
    -webkit-box-shadow: inset 0 -10px 10px -10px $light-grey-primary;
    box-shadow: inset 0 -10px 10px -10px $light-grey-primary;
  }

  &.form-shadow-bottom--hidden {
    &:after {
      display: none;
    }
  }
}

.header-background--full-width {
    &::after {
      position: absolute;
      content: "";
      background-color: $light-grey-fifth;
      z-index: -1;

      height: 400px;

      border-bottom: 1px solid $light-grey-fourth;

      padding-bottom: 40px;
      transform: translateY(-100%) translateY(40px);
      width: calc(100% + #{$main-padding-size} * 2);

      left: -$main-padding-size;
      @include devices(laptop) {
        width: calc(100% + #{$main-padding-size-x-laptop} * 2);
        left: -$main-padding-size-x-laptop;

        padding-bottom: 20px;
        transform: translateY(-100%) translateY(20px);
      }
    }
}


.add-form-action-buttons--pt-20 {
  padding-top: 20px;
}
