@import '../variables';

.mat-mdc-snack-bar-container.snackbar-error-panel {
  height: auto;
  min-height: 40px;
  background-color: $status-error;
  border-radius: 4px;
  color: white;
  box-shadow: 4px 4px 0 lighten($status-error, 30%);
  .mat-mdc-button {
    color: white;
  }
  strong {
    font-weight: 600;
  }
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .mdc-snackbar__surface {
    background-color: $status-error;
    box-shadow: none;
  }

  .mat-mdc-snack-bar-label {
    padding: 0;
  }

  .snackbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mat-mdc-snack-bar-container.snackbar-success-panel {
  height: auto;
  min-height: 40px;
  background-color: $status-success;
  border-radius: 4px;
  color: white;
  box-shadow: 4px 4px 0 lighten($status-success, 30%);
  .mat-mdc-button {
    color: white;
  }
  strong {
    font-weight: 600;
  }
  padding: 0;
  .mdc-snackbar__surface {
    background-color: $status-success;
    box-shadow: none;
  }

  .mat-mdc-snack-bar-label {
    padding: 0;
  }

  .snackbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mat-mdc-snack-bar-container.snackbar-warning-panel {
  height: auto;
  min-height: 40px;
  background-color: $status-warning;
  border-radius: 4px;
  color: white;
  box-shadow: 4px 4px 0 lighten($status-warning, 30%);
  .mat-mdc-button {
    color: white;
  }
  strong {
    font-weight: 600;
  }
  padding: 0;
  .mdc-snackbar__surface {
    background-color: $status-warning;
    box-shadow: none;
  }

  .mat-mdc-snack-bar-label {
    padding: 0;
  }

  .snackbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
