@import "../variables";
@import "../mixins";

.tabs-wrapper--background {
  &::after {
    position: absolute;
    content: "";
    background-color: $light-grey-fifth;
    z-index: -1;

    height: 400px;
    transform: translateY(-100%);
    width: calc(100% + #{$main-padding-size} * 2);

    left: -$main-padding-size;
    @include devices(laptop) {
      width: calc(100% + #{$main-padding-size-x-laptop} * 2);
      left: -$main-padding-size-x-laptop;
    }
  }
}

.add-form-action-buttons--background {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    top:0;
    background-color: $light-grey-fifth;
    overflow: hidden;
    z-index: -1;

    height: calc(100% + #{$main-padding-size});
    width: calc(100% + #{$main-padding-size} * 2);
    left: -$main-padding-size;
    @include devices(laptop) {
      height: calc(100% + #{$main-padding-size-y-laptop});
      width: calc(100% + #{$main-padding-size-x-laptop} * 2);
      left: -$main-padding-size-x-laptop;
    }
  }
}
