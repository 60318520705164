@import "../variables";

.custom-expansion-panel {
  p {
    margin: 0;
  }

  &.mat-expansion-panel {
    background-color: $light-grey-fifth;
  }

  &.custom-expansion-panel-description--align-end .mat-expansion-panel-header-description  {
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;
  }

  .mat-expansion-panel-header.custom-expansion-panel-header {
    height: initial;
    padding-left: 0;
    padding-right: 20px;
    background: $light-grey-fifth;

    &.custom-expansion-panel-header--padding-r-0 {
      padding-right: 0;
      .mat-expansion-panel-header-description {
        margin-right: 0;
      }
    }

    &.mat-expanded:hover {
      background: $light-grey-fifth;
    }

    &.mat-expanded:focus {
      background: $light-grey-fifth;
    }

    p {
      margin: 0;
    }

    &.custom-expansion-panel-header--space .mat-content {
      justify-content: space-between;
    }

  }
  .form-controls-group {
    padding: 20px 20px 20px 0;
    background: $light-grey-fifth;
    border-radius: 4px;
    margin-bottom: 0;

    height: initial;

    font-size: inherit;
    font-weight: inherit;

    hr {
      background-color: $light-grey-third;
      height: 1px;
      border: none;
      margin: 20px 0;
    }
  }
}

.mat-expansion-panel.custom-expansion-panel {
  &:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.mat-expansion-panel:not(.mat-expanded).custom-expansion-panel .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: $light-grey-fifth;
}
